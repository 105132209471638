import React from 'react'

import comparingDocumentVsRelational from '../assets/images/comparing-document-vs-relational/relational_vs_doc1.png'
import normalizingData from '../assets/images/comparing-document-vs-relational/normalizing_data.png'
import relatingDocs from '../assets/images/comparing-document-vs-relational/relating_docs.png'

const ComparingDocumentVsRelational = () => {
  return (
    <div className="page--comparing-document-vs-relational m-8 mb-12 wrapper">

      <div>
        <h1>Comparing Document Databases and Relational Databases</h1>
        <p>
          In a relational database system (RDBMS) you must define a schema before adding records to a database.
        </p>
        <p>
          By contrast, a <strong>document-oriented database</strong> contains documents, which are records that describe the data in the document,
          as well as the actual data. In shorthand, we tend to call these "document-oriented" databases a "<strong>document database</strong> or a <strong>NoSQL database</strong>" or
          "<strong>NoSQL document databases</strong>"
        </p>

        <h2>Schema vs Documents</h2>
        <p>
          The schema is the structure described in
          a formal language supported by the database and provides a blueprint
          for the tables in a database and the relationships between tables of
          data. Within a table, you need to define constraints in terms of rows
          and named columns as well as the type of data that can be stored in
          each column.
        </p>
        <p>
          In a document DBMS (database management system), documents can be as complex as you choose; you can use nested data to provide additional
          sub-categories of information about your object. Also, when utilizing this type of document storage database, you can use one or more
          documents to represent a real-world object.
        </p>
      </div>

      <div>
        <h3>Example</h3>
        <p>
          The following compares a conventional table with document-based database objects:
        </p>
        <img src={comparingDocumentVsRelational} alt="comparing document vs relational" width="50%" />
        <p>
          In this example, we have a table that represents beers and their
          respective attributes:
        </p>
        <ul>
          <li>ID</li>
          <li>Beer Name</li>
          <li>Brewer</li>
          <li>Bottles Available</li>
        </ul>
        <p>
          The relational model conforms
          to a schema with a specified number of fields which represent a
          specific purpose and data type. The equivalent document-based model
          has an individual document per beer; each document contains the same
          types of information for a specific beer.
        </p>
        <p>
          In a document-oriented model, data objects are stored as documents;
          each document stores your data and enables you to update the data or
          delete it. Instead of columns with names and data types, you describe
          the data in the document, and provide the value for that
          description.
        </p>
      </div>

      <div>
        <h2>Modifying Schema vs Modifying Documents</h2>
        <p>
          If you wanted to add attributes to a beer in a relational
          model, you would need to modify the database schema to include the
          additional columns and their data types.

          In the case of document-based
          data, you would add additional key-value pairs into your documents to
          represent the new fields in your <strong>document-oriented database</strong>.
        </p>

        <h3>Data Normalization</h3>
        <p>
          The other characteristic of a relational database is data normalization–this means you decompose data into smaller, related tables. The
          figure below illustrates this:
        </p>
        <img src={normalizingData} alt="normalizing data" width="50%" />
        <p>
          In the relational model, data is shared across multiple tables. The
          advantage to this model is that there is less duplicated data in the
          database. If you did not separate beers and brewers into different
          tables and had one beer table instead, you would have repeated
          information about breweries for each beer produced by that brewer.
        </p>

        <h3>Normalization and Scaling</h3>
        <p>
          The problem with this approach is that when you change information
          across tables, you need to lock those tables simultaneously to ensure
          information changes across the table consistently. Because you also
          spread information across a rigid structure, it makes it more
          difficult to change the structure during production, and it is also
          difficult to distribute the data across multiple servers.
        </p>

        <h3>Documents and Scaling</h3>
        <p>
          In the document store database, you could choose to have two
          different document structures: one for beers, and one for
          breweries. Instead of splitting your application objects into tables
          and rows, you would turn them into documents. By providing a reference
          in the beer document to a brewery document, you create a relationship
          between the two entities:
        </p>
        <img src={relatingDocs} alt="relating docs" width="50%" />
        <p>
          In this example there are two different beers from the Amstel
          brewery. Each beer is represented as a separate document and references
          the brewery in the brewer field.
        </p>

        <h2>Making the Switch to Couchbase</h2>
        <p>
          If you are thinking about moving your data out of a relational database and into a <strong>document-oriented database</strong>, 
          or you are considering Couchbase for your next project, we have many tools to ease the transition.
        </p>

        <h3>Can I still write SQL queries?</h3>
        <p>
          Yes! Couchbase is the only NoSQL database with a full N1QL implementation. Want to try it out?
          The <a rel="noopener noreferrer" href="https://developer.couchbase.com/webui-cb65">Quickstart with Couchbase 6.5 WebUI</a> features N1QL.
          You'll be up and running in minutes.
          You can also try the <a rel="noopener noreferrer" href="https://query-tutorial.couchbase.com/tutorial"> online N1QL Tutorial</a>
          a guided tour of N1QL and a sandbox interface,
          right in your browser.
        </p>

        <h3>What about ACID transactions?</h3>
        <p>
          Early on, NoSQL databases did not support ACID transactions. However, that has changed.
          Couchbase now offers <a rel="noopener noreferrer" href="https://www.couchbase.com/transactions" >ACID Transactions</a>
        </p>

        <h3>How do I migrate?</h3>
        <p>
          There are many approaches to this. Here are some links for further research:
        </p>

        <p>
          <ul>
            <li>
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/install/migrate-mysql.html" target="_blank">Data Model – Mapping from MySQL to Couchbase Server</a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="https://blog.couchbase.com/how-to-use-n1ql-to-migrate-oracle-data-into-couchbase/" target="_blank">How to use N1QL to migrate Oracle data into Couchbase</a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="https://docs.couchbase.com/server/current/tools/cbimport.html" target="_blank">cbimport: a tool to import CSV or JSON data into Couchbase</a>
            </li>
            <li>
              <a rel="noopener noreferrer" href="https://blog.couchbase.com/migrating-data-from-mongodb-to-couchbase/" target="_blank">Migrating Data from MongoDB to Couchbase</a>
            </li>
          </ul>
        </p>

        <h2>More questions?</h2>
        <p>
          Got a question about something not covered in this document? Ask on the <a rel="noopener noreferrer" href="http://forums.couchbase.com/" target="_blank">Couchbase Forums</a>.
        </p>
        
        <h2>Summary</h2>
        <p>
          The document data model approach
          provides several upsides compared to the traditional RDBMS
          model. First, because information is stored in documents, updating a
          schema is a matter of updating the documents for that type of
          object. This can be done with no system downtime. Second, you can
          distribute the information across multiple servers with greater
          ease. Since records are contained within entire documents, it makes it
          easier to move, or replicate an entire object to another server.
        </p>
      </div>

    </div>
  )
}
export default ComparingDocumentVsRelational